// Vision UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";

import {IoHome, IoLogoAndroid} from "react-icons/io5";
import { FaRobot } from "react-icons/fa";
import Installer from "./layouts/trading-bots/installer";
import Licenses from "./layouts/trading-bots/licenses";
import Access from "./layouts/umbrae/access";

const routes = [
  {
    type: "collapse",
    name: "Trading Bots",
    key: "trading-bots",
    icon: <FaRobot size="15px" color="inherit" />,
    collapse: [
      {
        name: "Installer",
        key: "installer",
        route: "/trading-bots/installer",
        component: Installer,
      },
      {
        name: "Licenses",
        key: "licenses",
        route: "/trading-bots/licenses",
        component: Licenses,
      },
      {
        name: "Umbrae Bot",
        key: "umbrae",
        route: "/umbrae/access",
        component: Access,
      },
    ],
  },
];


export default routes;