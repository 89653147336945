/**

 =========================================================
 * Vision UI PRO React - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com/)

 * Design and Coded by Simmmple & Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

 */

// @mui material components
import Grid from "@mui/material/Grid";
import breakpoints from "assets/theme/base/breakpoints";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Footer from "examples/Footer";

// Vision UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import {Suspense, useEffect, useState} from "react";
import {useUser} from "../../../context";
import DownloadCard from "../../trading-bots/installer/components/DownloadCard";
import {FaRobot} from "react-icons/fa";
import Card from "@mui/material/Card";
import VuiSelect from "../../../components/VuiSelect";
import FormField from "../../trading-bots/licenses/FormField";
import VuiButton from "../../../components/VuiButton";

function Access() {
    const { values } = breakpoints;
    const { user, setUser } = useUser();

    const [loadingData, setLoadingData] = useState(false);
    const [hasAccess, setHasAccess] = useState(false);
    const [telegramId, setTelegramId] = useState("");

    const handleTelegramIdChange = (event) => {
        setTelegramId(event.target.value);
    };

    useEffect(() => {
        // Define the async function inside useEffect and call it immediately
        const fetchAccess = async () => {
            await loadAccess();
        };
        fetchAccess();
    }, []); // Empty dependency array means it runs once on mount

    const loadAccess = async () => {
        setLoadingData(true);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/umbrae/access`,
                {
                    headers: {
                        Authentication: "Bearer " + user.token,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status !== 200) {
                setLoadingData(false);
                setHasAccess(false);
                return;
            }

            const data = await response.json();
            setHasAccess(data.has_access || false);
            setTelegramId(data.telegramId || ""); // Ensure telegramId updates here

        } catch (e) {
            console.error("Error loading access:", e);
            setHasAccess(false);
            setTelegramId("");
        }

        setLoadingData(false);
    };

    const UpdateAccess = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/umbrae/update-ids`,
            {
                method: "POST",
                body: JSON.stringify({
                    telegramId: telegramId,
                }),
                headers: {
                    Authentication: "Bearer " + user.token,
                    "Content-Type": "application/json",
                },
            }
        );

        if (response.status !== 200) {
            return;
        }

        const data = await response.json();
        setHasAccess(data.success || false);
        await loadAccess(); // Reload access to ensure UI updates
    };

    if (loadingData) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <VuiBox py={3}>
                    <VuiTypography variant="h6" color="white">
                        Loading...
                    </VuiTypography>
                </VuiBox>
                <Footer />
            </DashboardLayout>
        );
    }

    if (!hasAccess) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <VuiBox py={3}>
                    <VuiTypography variant="h5" color="white" textAlign="center">
                        You need to be a TripleOG to access Umbrae Bot in the beta testing stage.
                    </VuiTypography>
                </VuiBox>
                <Footer />
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <VuiBox py={3}>
                <Grid container>
                    <Grid item xs={12} lg={7}>
                        <VuiBox mb={3} p={1}>
                            <VuiTypography
                                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                                textTransform="capitalize"
                                fontWeight="bold"
                                color="white"
                            >
                                Umbrae Access
                            </VuiTypography>
                        </VuiBox>
                    </Grid>
                </Grid>
            </VuiBox>

            <VuiBox mt={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={9}>
                        <VuiBox mb={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Card id="create-license">
                                        <VuiBox mb="40px">
                                            <VuiTypography variant="lg" color="white" fontWeight="bold">
                                                Add details to gain access to Umbrae Bot.
                                            </VuiTypography>
                                        </VuiBox>
                                        <VuiBox component="form">
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <FormField
                                                        label="Telegram ID"
                                                        placeholder="Enter your telegram ID..."
                                                        inputProps={{ type: "text" }}
                                                        value={telegramId} // Controlled input
                                                        onChange={handleTelegramIdChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <VuiBox mb={1} mt={2}>
                                                <VuiTypography variant="body2" color="text">
                                                    You can get your telegram id by searching for the "userinfobot" in
                                                    Telegram, starting a chat, and typing "/start". <br />The bot will reply
                                                    with your Telegram ID.
                                                </VuiTypography>
                                            </VuiBox>
                                            <br />
                                            <VuiBox
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="flex-end"
                                                flexWrap="wrap"
                                            >
                                                <VuiBox ml="auto">
                                                    <VuiButton
                                                        variant="contained"
                                                        color="info"
                                                        size="small"
                                                        onClick={UpdateAccess}
                                                    >
                                                        Update
                                                    </VuiButton>
                                                </VuiBox>
                                            </VuiBox>
                                        </VuiBox>
                                    </Card>
                                </Grid>
                            </Grid>
                        </VuiBox>
                    </Grid>
                </Grid>
            </VuiBox>

            <Footer />
        </DashboardLayout>
    );
}

export default Access;
